import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import defaultConnect from "../../../../components/redux/defaultConnect";
import "./styles.css";

const ModalMonitor = ({ vehicle, closeModal }) => {
  const gpsTimeArray = vehicle.timestampGPS
    .match(/.{1,2}/g)
    .map(x => parseInt(x, 10));

  let working = vehicle.working ? (
    <span className="badge badge-primary" title="Trabajando">
      Trabajando
    </span>
  ) : (
    <span className="badge badge-secondary" title="Trabajando">
      Inactivo
    </span>
  );

  let power = null;
  switch (vehicle.vehicle.power) {
    case true:
      power = (
        <span className="badge badge-success" title="Estado motor">
          Encendido
        </span>
      );
      break;
    case false:
      power = (
        <span className="badge badge-danger" title="Estado motor">
          Apagado
        </span>
      );
      break;
    default:
      power = (
        <span className="badge badge-dark" title="Estado motor">
          N/A
        </span>
      );
      break;
  }

  let connection = null;
  switch (vehicle.socket) {
    case true:
      connection = (
        <span className="badge badge-success" title="Conexión socket">
          Conectado
        </span>
      );
      break;
    case false:
      connection = (
        <span className="badge badge-secondary" title="Conexión socket">
          Desconectado
        </span>
      );
      break;
    default:
      connection = (
        <span className="badge badge-dark" title="Conexión socket">
          N/A
        </span>
      );
      break;
  }

  let gps = null;
  switch (vehicle.gps_signal) {
    case true:
      gps = (
        <span className="badge badge-success" title="Señal GPS">
          Señal GPS
        </span>
      );
      break;
    case false:
      gps = (
        <span className="badge badge-warning" title="Señal GPS">
          Sin GPS
        </span>
      );
      break;
    default:
      gps = (
        <span className="badge badge-dark" title="Señal GPS">
          N/A
        </span>
      );
      break;
  }

  return (
    <div className="modal-monitor">
      <div className="backdrop">
        <div className="card animate-in">
          <div className="card-header">
            <h5 className="modal-title">
              Vehículo: {vehicle.vehicle.key_vehicle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body detalles">
            <div className="row">
              <div className="col-12 col-md-12 dato">
                <p className="label">Estados</p>
                <div className="status">
                  {working}
                  {connection}
                  {power}
                  {gps}
                </div>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Velocidad</p>
                <p className="value">{vehicle.speed.toFixed(2)} km/h</p>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Dirección</p>
                <p className="value">{vehicle.direction}</p>
              </div>

              <div className="col-12 col-md-12 dato">
                <p className="label">Ultima actualización</p>
                <p className="value">
                  <span title="GPS time" style={{ marginRight: ".5rem" }}>
                    {moment(
                      new Date(
                        gpsTimeArray.length == 6 ?
                          Date.UTC(
                            2000 + gpsTimeArray[0],
                            gpsTimeArray[1] > 0 ? gpsTimeArray[1] - 1 : 0,
                            gpsTimeArray[2],
                            gpsTimeArray[3], // + 7, // assuming time is in -7
                            gpsTimeArray[4],
                            gpsTimeArray[5]
                          ) :
                          Number(vehicle.timestampGPS) * 1000
                      )
                    ).format("DD-MMM-YYYY HH:mm:ss")}
                  </span>
                  <span
                    title="Server time"
                    style={{ fontSize: "0.8em", color: "#777" }}
                  >
                    ({moment(vehicle.timestamp).format("DD-MMM-YYYY HH:mm:ss")})
                  </span>
                </p>
              </div>

              <div className="col-12">
                <div className="separador" />
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Clave de vehículo</p>
                <p className="value">{vehicle.vehicle.key_vehicle}</p>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Teléfono</p>
                <p className="value">{vehicle.vehicle.phone_number}</p>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Descripción</p>
                <p className="value">{vehicle.vehicle.description}</p>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Placas</p>
                <p className="value">{vehicle.vehicle.plate_number}</p>
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Concesionario</p>
                {vehicle.vehicle.id_user ? (
                  <Link
                    className="badge badge-primary"
                    to={`/panel/concesionarios/concesionario/detalles/${vehicle.vehicle.id_user}`}
                  >
                    #{vehicle.vehicle.user.id_user} {vehicle.vehicle.user.name}{" "}
                    {vehicle.vehicle.user.last_name}
                  </Link>
                ) : (
                  <p className="value">Sin concesionario asignado</p>
                )}
              </div>

              <div className="col-12 col-md-6 dato">
                <p className="label">Chofer</p>
                {vehicle.vehicle.id_driver ? (
                  <Link
                    className="badge badge-primary"
                    to={`/panel/choferes/chofer/detalles/${vehicle.vehicle.id_driver}`}
                  >
                    #{vehicle.vehicle.driver.id_driver}{" "}
                    {vehicle.vehicle.driver.name}{" "}
                    {vehicle.vehicle.driver.last_name}
                  </Link>
                ) : (
                  <p className="value">Sin concesionario asignado</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default defaultConnect(ModalMonitor);
