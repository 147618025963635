import React from 'react';
import './styles.css';
import TablaAlertas from './tablaAlertas';

const Alertas = ({ match: { url } }) => (
  <div className="container alertas">
    <div className="row">
      <div className="col">

        <h2>Administración de Alertas</h2>

        <div className="card mirai-shadow">
          <h5 className="card-header">Alertas de vehículos</h5>
          <div className="card-body">

            <TablaAlertas />

          </div>
        </div>

      </div>
    </div>
  </div>
);

export default Alertas;
