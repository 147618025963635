import React from "react";
import Mapa from '../mapa';

const InfoGeocerca = ({ geofence, map, mapRef }) => {
    return (
        <div className="card mirai-shadow">
            <h5 className="card-header">Información general</h5>
            <div className="row card-body">
                <div className="col-12 col-md-6 dato">
                    <p className="label">ID</p>
                    <p className="value">{geofence.id_geofence}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Clave</p>
                    <p className="value">{geofence.key}</p>
                </div>

                <Mapa
                    map={map}
                    mapRef={mapRef}
                    readOnly={true}
                />
            </div>
        </div>
    );
};

export default InfoGeocerca;
