import React from 'react';
import {
    Circle,
    CircleMarker,
    Map,
    Polygon,
    Polyline,
    Popup,
    Rectangle,
    TileLayer,
} from 'react-leaflet';
import defaultConnect from '../../../components/redux/defaultConnect';

const GeocercaMapa = ({ map, mapRef, addPoint, resetMap, readOnly = false }) => {

    return (
        <div className='col-md-12'>
            {!readOnly &&
                <>
                    <h5 className='text-muted mb-1'>Definir geocerca</h5>
                    <div class="controls">
                        <p className='mt-2'>
                            Para crear la geocerca haga click en el mapa para poner un marcador y rodee con marcadores el area deseada.
                        </p>
                        <button type="button" class="btn btn-sm btn-light" onClick={resetMap}>
                            Limpiar mapa
                        </button>
                    </div>
                </>}
            <Map ref={mapRef} key={map.key} center={map.position} zoom={map.zoom} maxZoom={18} onclick={addPoint} animate>
                <TileLayer
                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={map.polygonPoints} />
            </Map>
        </div>

    )

};

export default GeocercaMapa;