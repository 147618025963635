import { connect } from 'react-redux';
import { updateLoadingBar, pushAlertaSuccess, pushAlertaError, pushAlerta } from '../../store/reducers';

const defaultConnect = (component) => (
  connect(
    () => ({}),
    dispatch => ({
      pushAlerta: (alerta) => dispatch(pushAlerta(alerta)),
      pushSuccess: (msg, timeout = 0) => dispatch(pushAlertaSuccess(msg, timeout)),
      pushError: (error, timeout = 0) => dispatch(pushAlertaError(error, timeout)),
      setLoading: (loadingBar) => dispatch(updateLoadingBar(loadingBar))
    }),
    null,
    { forwardRef: true },
  )(component)
);

export default defaultConnect;
