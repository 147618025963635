import React from 'react';
import defaultConnect from '../../../../../components/redux/defaultConnect';
import makeRequest from '../../../../../lib/apiService';

import Table from "../../../../../components/table/table";
import Confirmacion from '../../../../../components/confirmacion/confirmacion';
import ModalVehicle from './modal';
import { WafoFormInput } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const defaultFilters = {
    search: '',
};

class VehiculosAsignados extends React.Component {
    state = {
        tabla: {
            rows: [],
            total: 0,
            activePage: 1,
            size: 10,
        },
        confirmation: {
            show: false,
            message: '',
            accept: f => f,
            acceptParams: {},
        },
        modal: {
            show: false,
        },
        filters: defaultFilters,
        autocomplete: React.createRef()
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (page = 1) => {
        const { tabla: { size }, filters } = this.state;
        const { setLoading, pushError, id } = this.props;

        try {
            setLoading(true);
            const resp = await makeRequest(
                `geofences/${id}/vehicles?limit=${size}&page=${page}`
                + `${filters.search ? `&search=${filters.search}` : ''}`,
                { method: 'GET' },
            );
            this.setState((prevState) => ({
                tabla: {
                    ...prevState.tabla,
                    rows: resp.rows,
                    total: resp.count,
                    activePage: page,
                }
            }), () => { setLoading(false); });
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    }

    handleFilterChange = (event) => {
        const { target: { name, value } } = event;
        this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                [name]: value,
            }
        }), this.getData);
    }

    paginationHandler = (event, value) => {
        const { tabla, tabla: { activePage } } = this.state;

        switch (event) {
            case 'page': {
                let pagina = value;
                if (pagina === 'prev') {
                    pagina = (activePage - 1);
                } else if (pagina === 'next') {
                    pagina = (activePage + 1);
                }
                this.getData(pagina);
                break;
            }
            case 'size':
                this.setState({ tabla: { ...tabla, size: parseInt(value, 10) } }, () => {
                    this.getData(activePage);
                });
                break;
            default: break;
        }
    }

    toggleConfirmation = (confirmation) => this.setState((prevState) => ({
        confirmation: {
            ...prevState.confirmation,
            ...confirmation,
            show: !prevState.confirmation.show,
        },
    }));

    deleteConfirmation = (id) => this.toggleConfirmation({
        message: 'El vehículo ya no estará vinculado a la geocerca.',
        accept: this.deleteVehicle,
        acceptParams: { id },
    });

    deleteVehicle = async () => {
        const { setLoading, pushError, pushSuccess, id: id_geofence } = this.props;
        const {
            tabla: { activePage },
            confirmation: { acceptParams: { id: id_vehicle } }
        } = this.state;

        try {
            this.toggleConfirmation();
            setLoading(true);
            await makeRequest(
                `geofences/${id_geofence}/vehicles/${id_vehicle}`,
                { method: 'PUT' },
            );
            setLoading(false);
            pushSuccess('Se ha realizado el cambio con exito.', 3000);
            this.getData(activePage);
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    }

    openModal = () => this.setState((prevState) => ({
        modal: {
            ...prevState.modal,
            show: true
        }
    }));

    closeModal = () => this.setState({ modal: { show: false } });

    render() {
        const { tabla, filters, confirmation, modal } = this.state;
        const { id } = this.props;
        const columns = ['Clave', 'Placas', 'Descripción', 'Opciones'];

        const rows = tabla.rows.map(row => ({
            key_vehicle: row.key_vehicle,
            plate_number: row.plate_number,
            description: row.description,
            options: { id: row.id_vehicle }
        }));

        const configTable = {
            columnDef: {
                options: val => (
                    <React.Fragment>
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.deleteConfirmation(val.id) }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </React.Fragment>
                ),
            },
            columnStyle: {
                options: {
                    whiteSpace: 'nowrap',
                },
            },
        };
        return (
            <>
                <div className="card mirai-shadow">
                    <h5 className="card-header">Vehículos asignados</h5>
                    <div className="card-body">
                        <div className="row filtros" style={{ 'marginBottom': '20px' }}>
                            <WafoFormInput
                                type="text"
                                name="search"
                                placeholder="Filtrar por clave, placas o descripción"
                                customClass="col-12 col-md-6"
                                value={filters.search}
                                handleInputChange={this.handleFilterChange}
                                extraProps={{ "autocomplete": "off" }}
                            />
                            <div className="botones col-12 col-md-6">
                                <button type="button" className="btn btn-primary" onClick={this.openModal}>
                                    <FontAwesomeIcon icon={faPlus} style={{ 'marginRight': '5px' }} />
                                    Asignar vehículo
                                </button>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            data={rows}
                            configTable={configTable}
                            pagination={{
                                total: tabla.total,
                                page: tabla.activePage,
                                limit: tabla.size
                            }}
                            paginationEvent={this.paginationHandler}
                            updateTable={() => {
                                this.getData(tabla.activePage);
                            }}
                            tableClass={"datatable-wrapper"}
                        />
                    </div>
                </div>
                {modal.show && <ModalVehicle closeModal={this.closeModal} ref={this.autocomplete} id={id} updateTable={this.getData} />}
                {confirmation.show && <Confirmacion message={confirmation.message} accept={confirmation.accept} cancel={() => { this.toggleConfirmation(undefined); }} />}
            </>

        );
    }
}

export default defaultConnect(VehiculosAsignados);