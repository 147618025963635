import React from 'react';
import moment from 'moment-timezone';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

moment.locale('es');

class DetallesConcesionario extends React.Component {
  state = {
    user: {},
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const user = await makeRequest(
        `users/${id}`,
        { method: 'GET' },
      );
      this.setState({ user });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { user } = this.state;

    let status = null;
    switch (user.disabled) {
      case true:
        status = <span className="badge badge-warning">Deshabilitado</span>;
        break;
      case false:
        status = <span className="badge badge-success">Activo</span>;
        break;
      default:
        status = <span className="badge badge-dark">N/A</span>;
        break;
    }

    return (
      <div className="detalles-concesionario detalles">
        <div className="row">

          <div className="col-12 header">
            <h3>Concesionario #{user.id_user}</h3>
            {status}
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Nombre completo</p>
            <p className="value">{`${user.name} ${user.last_name}`}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Correo electrónico</p>
            <p className="value">{user.email}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Número de teléfono</p>
            <p className="value">{user.phone_number}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Descripción</p>
            <p className="value">{user.description}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Fecha de registro</p>
            <p className="value">{moment(user.createdAt).format('DD-MMMM-YYYY hh:mm a')}</p>
          </div>

        </div>
      </div>
    );
  }
}

export default defaultConnect(DetallesConcesionario);
