import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TablaChoferes from './tablaChoferes';
import FormaChofer from './formaChofer';
import DetallesChofer from './detallesChofer';

const Choferes = ({ match: { url } }) => (
  <div className="container">
    <div className="row">
      <div className="col">

        <h2>Administración de Choferes</h2>

        <ul className="sl-pills nav nav-pills">
          <li className="nav-item">
            <NavLink exact to="/panel/choferes" className="btn btn-sm">
              Lista de choferes
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact to="/panel/choferes/chofer" className="btn btn-sm">
              <FontAwesomeIcon icon={faPlus} />
              Nuevo chofer
            </NavLink>
          </li>
        </ul>

        <div className="card mirai-shadow border-top-square">
          <div className="card-body">
            <Switch>
              <Route exact path={`${url}`} component={TablaChoferes} />
              <Route exact path={`${url}/chofer`} component={FormaChofer} />
              <Route exact path={`${url}/chofer/:id`} component={FormaChofer} />
              <Route exact path={`${url}/chofer/detalles/:id`} component={DetallesChofer} />
            </Switch>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default Choferes;
