import React from 'react';
import PropTypes from 'prop-types';
import { WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import { Map, TileLayer, Marker } from 'react-leaflet';
import AntPath from 'react-leaflet-ant-path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMap, faEraser, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { markerStart, markerFinish, markerCustom } from './icons';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '@wafo/forms/lib/wafo-forms.css';
import './styles.css';

moment.locale('es');

const LocationsMapa = ({
  locations, map, mapRef, forceMapUpdate, resetMap, customMarker,
  getData, filters, clearFilters, vehiclesFilter, handleFilterChange, vehicleAutocompleteRef,
}) => {
  const positions = React.useMemo(() => {
    return locations.map((location) => ([location.latitude, location.longitude]));
  }, [locations]);

  return (
    <div className="mapa-locations">
      <div className="map card mirai-shadow">
        <h5 className="card-header">Mapa con ubicaciones historicas</h5>
        <div className="card-body">

          <div className="controls">
            <div className="line">
              <WafoFormAutocomplete
                ref={vehicleAutocompleteRef}
                name="vehicle"
                label="Vehículo"
                placeholder="Seleccione un vehículo"
                items={vehiclesFilter}
                filterItemsFN={(items, query) => items.filter(item => (
                  item.key_vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1
                  // || item.imei.indexOf(query) !== -1
                ))}
                customInputFN={item => `${item.key_vehicle}`}
                customItemFN={item => (
                  <p style={{ margin: '0' }}>
                    <span>{item.key_vehicle}</span>
                    <br />
                    <span style={{ fontSize: '0.8em', color: '#777' }}>{item.description}</span>
                  </p>
                )}
                handleInputChange={handleFilterChange}
              />
              <WafoFormInput
                type="datetime-local"
                name="fromDate"
                customClass="col-6"
                label="Fecha inicial"
                value={filters.fromDate}
                handleInputChange={handleFilterChange}
              />
              <WafoFormInput
                type="datetime-local"
                name="toDate"
                customClass="col-6"
                label="Fecha final"
                value={filters.toDate}
                handleInputChange={handleFilterChange}
              />
            </div>
            <div className="line">
              <button type="button" className="btn btn-outline-secondary" onClick={forceMapUpdate}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </button>
              <button type="button" className="btn btn-outline-secondary" onClick={clearFilters}>
                <FontAwesomeIcon icon={faEraser} style={{ marginRight: '.25rem' }} />
                Limpiar filtros
                </button>
              <button type="button" className="btn btn-outline-secondary" onClick={resetMap}>
                <FontAwesomeIcon icon={faMap} style={{ marginRight: '.25rem' }} />
                Restablecer mapa
                </button>
              <button type="button" className="btn btn-sl-3" onClick={getData} disabled={!filters.vehicle}>
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: '.25rem' }} />
                Consultar historial
                </button>
            </div>
          </div>

          <Map ref={mapRef} key={map.key} center={map.position} zoom={map.zoom} maxZoom={18} animate>
            <TileLayer
              attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <AntPath positions={positions} options={{ delay: 1000, dashArray: [30, 60] }} />
            {locations.length &&
              <React.Fragment>
                <Marker
                  position={[locations[0].latitude, locations[0].longitude]}
                  // title={moment(locations[0].createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  title="Inicio de recorrido"
                  icon={markerStart}
                />
                <Marker
                  position={[locations[locations.length - 1].latitude, locations[locations.length - 1].longitude]}
                  // title={moment(locations[locations.length - 1].createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  title="Fin de recorrido"
                  icon={markerFinish}
                />
              </React.Fragment>
            }
            {customMarker &&
              <Marker
                position={[customMarker.latitude, customMarker.longitude]}
                title={customMarker.title}
                icon={markerCustom}
              />
            }
          </Map>
        
        </div>
        <div className="card-footer">
          <p>
            Mostrando un total de <strong>{locations.length}</strong> ubicaciones.
          </p>
        </div>
      </div>
    </div>
  );
};

LocationsMapa.propTypes = {
  locations: PropTypes.array,
};

LocationsMapa.defaultProps = {
  locations: [],
};

export default LocationsMapa;
