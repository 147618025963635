import React from 'react';
import { connect } from 'react-redux';
import { removeAlerta } from '../../store/reducers';
import './styles.css';

class Alerta extends React.Component {
  state = {
    css_class: 'animate-in'
  };

  componentDidMount() {
    const { css_class } = this.state;
    const { alerta } = this.props;
    if (alerta.timer && css_class === 'animate-in') {
      this.timeout = setTimeout(this.timerRemove, alerta.timer);
    }
  }

  timerRemove = () => {
    const { alerta, removeAlerta } = this.props;
    this.setState({ css_class: 'animate-out' }, () => {
      setTimeout(() => {
        removeAlerta(alerta.uuid);
      }, 400);
    });
  }

  removeAlerta = () => {
    const { alerta, removeAlerta } = this.props;
    removeAlerta(alerta.uuid);
    clearTimeout(this.timeout);
  }

  render() {
    const { css_class } = this.state;
    const { alerta } = this.props;

    return (
      <div className={`alert-sl ${css_class} alert-${alerta.type} alert alert-dismissible`}>
        <strong>{alerta.title}</strong>
        <br />
        {alerta.message}
        <button type="button" className="close" onClick={this.removeAlerta}>
          <span>&times;</span>
        </button>
      </div>
    );
  }
}

const AlertasList = ({ alertas, removeAlerta }) => (
  <ul className="alertas-sl">
    {alertas.map((alerta, i) => {
      if (i > 2) { return null; }
      return (<Alerta key={alerta.uuid} alerta={alerta} removeAlerta={removeAlerta} />);
    })}
  </ul>
);

export default connect(
  state => ({
    alertas: state.alertas
  }),
  dispatch => ({
    removeAlerta: (uuid) => dispatch(removeAlerta(uuid))
  })
)(AlertasList);