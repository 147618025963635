import { connect } from 'react-redux';
import { setMe, updateLoadingBar, pushAlertaSuccess, pushAlertaError, pushAlerta } from '../../store/reducers';

const meConnect = (component) => (
  connect(
    state => ({
      me: state.me
    }),
    dispatch => ({
      setMe: (me) => dispatch(setMe(me)),
      pushAlerta: (alerta) => dispatch(pushAlerta(alerta)),
      pushSuccess: (msg, timeout = 0) => dispatch(pushAlertaSuccess(msg, timeout)),
      pushError: (error, timeout = 0) => dispatch(pushAlertaError(error, timeout)),
      setLoading: (loadingBar) => dispatch(updateLoadingBar(loadingBar)),
    }),
    null,
    { forwardRef: true },
  )(component)
);

export default meConnect;
