import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from './components/privateRoute/privateRoute';
import PanelAdmin from './scenes/panel/panel';
import Login from './scenes/login/login';
import ForgotPassword from './scenes/login/forgot';
import ChangePassword from './scenes/login/change';
import storeFactory from './store/store';

const store = storeFactory();

const App = () => (
  <Provider store={store}>
    <Router>
      <div className="page-content">
        <Switch>
          {/* Auth */}
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login/forgot" component={ForgotPassword} />
          <Route path="/login/change/:token" component={ChangePassword} />
          {/* Panel */}
          <PrivateRoute path="/panel" component={PanelAdmin} />
        </Switch>
      </div>
    </Router>
  </Provider>
);

export default App;
