import React from 'react';
import moment from 'moment-timezone';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

import InfoGeocerca from './detallesGeocerca/infoGeocerca';
import LogsGeocerca from './detallesGeocerca/logsGeocerca';
import VehiculosAsignados from './detallesGeocerca/vehiculosAsignados/vehiculosAsignados';

moment.locale('es');

const initialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 14,
};

class DetallesGeocerca extends React.Component {
  state = {
    geofence: {},
    map: {
      ...initialPosition,
      key: Math.random(),
      polygonPoints: []
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const geofence = await makeRequest(
        `geofences/${id}`,
        { method: 'GET' },
      );
      this.setState({ geofence }, () => {
        setLoading(false);
      });

      const polygonPoints = geofence.polygon.coordinates[0].map(point => Object.values(point).reverse())

      this.setState((prevState) => ({
        map: {
          ...prevState.map,
          key: Math.random(),
          polygonPoints
        },
      }))

      this.map.flyTo(this.calculatePolygonCenter(polygonPoints), this.calculateZoomLevel(polygonPoints));

    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  /** ****************************** */
  /** Map stuff */
  /** ****************************** */

  // Setting map ref to acces leaflet methods.
  setMapRef = (event) => {
    if (event) {
      this.map = event.leafletElement;
      return this.map;
    }
  }

  calculateZoomLevel = (polygonCoordinates) => {
    const earthRadius = 6371000; // Radio de la Tierra en metros
    const numCoords = polygonCoordinates.length;
    let area = 0; // Inicializar el área en cero

    // Convertir las coordenadas geográficas a radianes
    const toRadians = (degrees) => {
      return degrees * (Math.PI / 180);
    };

    // Calcular el área del polígono
    for (let i = 0; i < numCoords; i++) {
      const p1 = polygonCoordinates[i];
      const p2 = polygonCoordinates[(i + 1) % numCoords];
      area += toRadians(p2[0] - p1[0]) * (
        2 +
        Math.sin(toRadians(p1[1])) +
        Math.sin(toRadians(p2[1]))
      );
    }

    // Multiplicar el área por el radio de la Tierra y dividir por 2
    area *= earthRadius * earthRadius / 2;

    // Retornar el área calculada
    if (Math.abs(area) < 100000) {
      return 16;
    } else if (Math.abs(area) < 1000000) {
      return 14;
    } else {
      return 11;
    }
  };

  calculatePolygonCenter = (polygonCoordinates) => {
    let totalX = 0;
    let totalY = 0;

    for (let i = 0; i < polygonCoordinates.length; i++) {
      totalX += polygonCoordinates[i][0];
      totalY += polygonCoordinates[i][1];
    }

    const centroidX = totalX / polygonCoordinates.length;
    const centroidY = totalY / polygonCoordinates.length;

    return [centroidX, centroidY];
  };

  /** ****************************** */
  /** End map stuff */
  /** ****************************** */

  render() {
    const { geofence, map } = this.state;
    const { match: { params: { id } } } = this.props;

    let status = null;
    switch (geofence.disabled) {
      case true:
        status = <span className="badge badge-warning">Deshabilitado</span>;
        break;
      case false:
        status = <span className="badge badge-success">Activo</span>;
        break;
      default:
        status = <span className="badge badge-dark">N/A</span>;
        break;
    }

    return (
      <div className="detalles-geocerca detalles">
        <div className="row">

          <div className="col-12 header">
            <h3>{geofence.name}</h3>
            {status}
          </div>

          {/** Info de geocerca */}
          <div className='col-12 col-md-6'>
            <InfoGeocerca
              geofence={geofence}
              map={map}
              mapRef={this.setMapRef}
            />
          </div>

          {/** Vehículos vinculados a geocerca */}
          <div className='col-12 col-md-6'>

            <VehiculosAsignados
              id={id} />

          </div>
        </div>
        <div className='row pt-4'>
          {/** Logs de geocerca */}
          <div className='col-12'>

            <LogsGeocerca
              id={id} />

          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(DetallesGeocerca);
