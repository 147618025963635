import React from 'react';
import Table from "../../../../components/table/table";
import { WafoFormAutocomplete } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import defaultConnect from '../../../../components/redux/defaultConnect';
import makeRequest from '../../../../lib/apiService';
import moment from 'moment';

const types = [
    {
        display: 'Entró a geocerca',
        value: 'geofenceIn',
    },
    {
        display: 'Salió de geocerca',
        value: 'geofenceOut',
    },
    {
        display: 'Geocerca activada',
        value: 'geofenceOn',
    },
    {
        display: 'Geocerca desactivada',
        value: 'geofenceOff',
    },
    {
        display: 'Geocerca activada - vehículo',
        value: 'geofenceVehicleOn',
    },
    {
        display: 'Geocerca desactivada - vehículo',
        value: 'geofenceVehicleOff',
    },
];

const defaultFilters = {
    vehicle: '',
    type: '',
};

class LogsGeocerca extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabla: {
                rows: [],
                total: 0,
                activePage: 1,
                size: 10,
            },
            filters: defaultFilters,
            vehiclesFilter: [],
        };

        this.typeAutocomplete = React.createRef();
        this.vehiclesAutocomplete = React.createRef();
    }

    componentDidMount() {
        this.getData();
        this.getVehiclesFilter();
    }

    getData = async (page = 1) => {
        const { tabla: { size }, filters } = this.state;
        const { setLoading, pushError, id } = this.props;

        try {
            setLoading(true);
            const resp = await makeRequest(
                `geofences/${id}/logs?limit=${size}&page=${page}`
                + `${filters.type ? `&type=${filters.type.value}` : ''}`
                + `${filters.vehicle ? `&id_vehicle=${filters.vehicle.id_vehicle}` : ''}`,
                { method: 'GET' },
            );
            this.setState((prevState) => ({
                tabla: {
                    ...prevState.tabla,
                    rows: resp.rows,
                    total: resp.count,
                    activePage: page,
                }
            }), () => { setLoading(false); });
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    }

    getVehiclesFilter = async () => {
        const { setLoading, pushError } = this.props;

        try {
            setLoading(true);
            const vehicles = await makeRequest(
                `vehicles/?limit=${999999}`,
                { method: 'GET' },
            );
            this.setState({
                vehiclesFilter: vehicles.rows,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    };

    paginationHandler = (event, value) => {
        const { tabla, tabla: { activePage } } = this.state;

        switch (event) {
            case 'page': {
                let pagina = value;
                if (pagina === 'prev') {
                    pagina = (activePage - 1);
                } else if (pagina === 'next') {
                    pagina = (activePage + 1);
                }
                this.getData(pagina);
                break;
            }
            case 'size':
                this.setState({ tabla: { ...tabla, size: parseInt(value, 10) } }, () => {
                    this.getData(activePage);
                });
                break;
            default: break;
        }
    }

    handleFilterChange = (event) => {
        const { target: { name, value } } = event;
        this.setState((prevState) => ({
            table: {
                ...prevState.table,
                activePage: 1
            },
            filters: {
                ...prevState.filters,
                [name]: value,
            }
        }), this.getData);
    }

    clearFilters = () => this.setState({ filters: defaultFilters }, () => {
        this.typeAutocomplete.current.clearForm();
        this.vehiclesAutocomplete.current.clearForm();
        this.getData();
    });

    render() {
        const { tabla, vehiclesFilter } = this.state;
        const columns = ['ID', 'Tipo', 'Usuario', 'Vehículo', 'Fecha', ''];

        const rows = tabla.rows.map(row => ({
            id: row.id_notification,
            type: row.type,
            user: row.user ? row.user.name : null,
            vehicle: row.key_vehicle || null,
            date: row.createdAt,
            options: {
                latitude: row.latitude,
                longitude: row.longitude
            }
        }));

        const configTable = {
            columnDef: {
                id: val => (<span>#{val}</span>),
                type: val => {
                    switch (val) {
                        case 'geofenceIn': return <span className="badge badge-light">Entró a geocerca</span>;
                        case 'geofenceOut': return <span className="badge badge-light">Salió de geocerca</span>;
                        case 'geofenceOn': return <span className="badge badge-light">Geocerca activada</span>;
                        case 'geofenceOff': return <span className="badge badge-light">Geocerca desactivada</span>;
                        case 'geofenceVehicleOn': return <span className="badge badge-light">Geocerca activada - vehículo</span>;
                        case 'geofenceVehicleOff': return <span className="badge badge-light">Geocerca desactivada - vehículo</span>;
                        default: return <span className="badge badge-light">{val}</span>;
                    }
                },
                date: val => (<span>{moment(val).format('DD-MMM-YYYY HH:mm:ss')}</span>),
                options: val => (
                    <React.Fragment>
                        {val.longitude && <a
                            className="btn btn-sm btn-light"
                            title="Ir a Google Maps"
                            href={`https://www.google.com/maps/search/?api=1&query=${val.latitude},${val.longitude}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                        </a>}
                    </React.Fragment>
                )
            },
            columnStyle: {
                options: {
                    whiteSpace: 'nowrap',
                },
            },
        };

        return (
            <div className="card mirai-shadow">
                <h5 className="card-header">Registros de geocerca</h5>
                <div className="card-body">
                    <div className="tabla-geocerca">
                        <div className="row filtros">

                            <WafoFormAutocomplete
                                ref={this.vehiclesAutocomplete}
                                name="vehicle"
                                customClass="col-12 col-md-4"
                                placeholder="Busqueda por vehículo"
                                items={vehiclesFilter}
                                filterItemsFN={(items, query) => items.filter(item => (
                                    item.imei.indexOf(query) !== -1
                                    || item.key_vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1
                                ))}
                                customInputFN={item => `${item.key_vehicle}`}
                                customItemFN={item => (
                                    <p style={{ margin: '0' }}>
                                        <span>{item.key_vehicle}</span>
                                        <br />
                                        <span style={{ fontSize: '0.8em', color: '#777' }}>{item.description}</span>
                                    </p>
                                )}
                                handleInputChange={this.handleFilterChange}
                                extraProps={{
                                    autoComplete: 'off',
                                }}
                            />

                            <WafoFormAutocomplete
                                ref={this.typeAutocomplete}
                                name="type"
                                customClass="col-12 col-md-4"
                                placeholder="Tipo de alerta"
                                items={types}
                                filterItemsFN={items => items}
                                customInputFN={item => item.display}
                                customItemFN={item => item.display}
                                handleInputChange={this.handleFilterChange}
                                itemsLimit={10}
                                extraProps={{
                                    autoComplete: 'off',
                                }}
                            />

                            <div className="botones col-12 col-md-4">
                                <button type="button" className="btn btn-light" onClick={this.clearFilters}>
                                    <FontAwesomeIcon icon={faEraser} style={{ marginRight: '.25rem' }} />
                                    Limpiar filtros
                                </button>
                            </div>

                        </div>

                        <Table
                            columns={columns}
                            data={rows}
                            configTable={configTable}
                            pagination={{
                                total: tabla.total,
                                page: tabla.activePage,
                                limit: tabla.size
                            }}
                            paginationEvent={this.paginationHandler}
                            updateTable={() => {
                                this.getData(tabla.activePage);
                            }}
                            tableClass={"datatable-wrapper"}
                        />

                    </div>
                </div>
            </div>
        );
    }
}

export default defaultConnect(LogsGeocerca);
