import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { WafoFormAutocomplete } from '@wafo/forms';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMap, faRedo, faEraser } from '@fortawesome/free-solid-svg-icons';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import moment from 'moment';
import ModalMonitor from '../modalMonitor/modalMonitor';
import GoogleAutocomplete from '../../../../components/customForms/googleAutocomplete/googleAutocomplete';
import defaultConnect from '../../../../components/redux/defaultConnect';
import makeRequest from '../../../../lib/apiService';
import StatusFilter from '../statusFilter';
// import { markerOnline, markerOffline, markerOff, markerNogps } from './icons';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '@wafo/forms/lib/wafo-forms.css';
import './styles.css';
import Leaflet from 'leaflet';

const markerOnline = require('../../../../assets/images/markers/marker-online.png');
const markerOffline = require('../../../../assets/images/markers/marker-offline.png');
const markerOff = require('../../../../assets/images/markers/marker-off.png');
const markerNogps = require('../../../../assets/images/markers/marker-nogps.png');
const markerWorking = require('../../../../assets/images/markers/marker-working.png');

const initialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 11,
};

const defaultFilters = {
  status: '',
};

function generateDivIcon(vehicle) {
  let img = <img src={markerOffline} alt="Offline" />; //gris
  if (!vehicle.socket) { //conectado
    img = <img src={markerOffline} alt="Offline" />; //gris
  } else if (!vehicle.vehicle.power) { //encendido
    img = <img src={markerOff} alt="Off" />; //rojo
  } else if (vehicle.socket && !vehicle.gps_signal) { //conectado y gps
    img = <img src={markerNogps} alt="Nogps" />; //amarillo
  } else if (vehicle.socket && vehicle.gps_signal) { //conectado y gps
    img = <img src={markerOnline} alt="Online" />; //verde
  } else if (vehicle.working) { //trabajando
    img = <img src={markerWorking} alt="Trabajando" />; //azul
  }

  const spanStyle = {
    backgroundColor: '#f5f5f5',
    color: '#333',
    padding: '.1rem .25rem',
    borderRadius: '.25rem',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    display: 'block',
    marginBottom: '.1rem',
  };

  return Leaflet.divIcon({
    className: 'customMapMarker',
    iconSize: [45, 65],
    iconAnchor: [22, 65],
    html: ReactDOMServer.renderToString(<div>
      <span style={spanStyle}>
        {vehicle.vehicle.key_vehicle}
      </span>
      {img}
    </div>),
  });
}

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {
        ...initialPosition,
        key: Math.random(),
      },
      filters: defaultFilters,
      vehiclesFilter: [],
      vehicles: [],
      updated: new Date(),
      modal: {
        show: false,
        vehicle: null,
      },
    };

    this.googleAutocomplete = React.createRef();
    this.vehicleAutocomplete = React.createRef();
    this.statusAutocomplete = React.createRef();
  }

  componentDidMount() {
    this.getVehiclesFilter();
    this.autoUpdateData();
  }

  componentWillUnmount() {
    clearInterval(this.dataLoop);
  }

  getVehiclesFilter = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const vehicles = await makeRequest(
        `vehicles/?limit=${999999}`,
        { method: 'GET' },
      );
      this.setState({
        vehiclesFilter: vehicles.rows,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  // Setting map ref to acces leaflet methods.
  setMapRef = (event) => {
    if (event) {
      this.map = event.leafletElement;
    }
  }

  // Changes map key to force re-render
  forceMapUpdate = () => this.setState((prevState) => ({
    map: {
      ...prevState.map,
      key: Math.random(),
    },
  }));

  // Resets maps position to default
  resetMap = () => {
    this.googleAutocomplete.current.clearForm();
    this.map.flyTo(initialPosition.position, initialPosition.zoom);
  }

  handleGoogleAutocomplete = (place) => {
    const { geometry: { viewport } } = place;
    const northEast = viewport.getNorthEast();
    const southWest = viewport.getSouthWest();
    this.map.fitBounds([
      [northEast.lat(), northEast.lng()],
      [southWest.lat(), southWest.lng()]
    ]);
  }

  // to use from outside (tablaMonitor.js)
  locateVehicle = (id_vehicle) => {
    this.handleFilterVehicle({
      target: {
        value: {
          id_vehicle
        },
      },
    });
  }

  // Data and filters

  // get vehicles from /monitor
  getData = async () => {
    const { filters } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `monitor/?limit=999999`
        + `${filters.status ? `&${filters.status.filter}=${filters.status.value}` : ''}`,
        { method: 'GET' },
      );
      this.setState({
        vehicles: resp.rows,
        updated: new Date()
      }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  autoUpdateData = () => {
    this.getData();
    this.dataLoop = setInterval(this.getData, 30000);
  }

  handleFilterChange = (event) => {
    const { target: { name, value } } = event;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      }
    }), this.getData);
  }

  handleFilterVehicle = (event) => {
    const { target: { value } } = event;
    const { vehicles } = this.state;
    const { pushAlerta } = this.props;

    if (!value) { return; }

    const vehicle = vehicles.find(x => x.vehicle.id_vehicle === value.id_vehicle);
    if (!vehicle) {
      pushAlerta({
        type: 'warning',
        title: 'Vehículo no encontrado',
        message: 'Se desconoce la ubicación del vehículo y no puede ser mostrado. Verifique los filtros.',
        timer: 5000,
      });
      this.map.flyTo(initialPosition.position, initialPosition.zoom);
      return;
    }
    this.map.flyTo([vehicle.latitude, vehicle.longitude], 18);
  }

  clearFilters = () => {
    this.googleAutocomplete.current.clearForm();
    this.vehicleAutocomplete.current.clearForm();
    this.statusAutocomplete.current.clearForm();
    this.setState({ filters: defaultFilters });
  }

  /** ***************************** */
  /** Modal */
  /** ***************************** */

  openModal = async (id) => {
    const { setLoading, pushError } = this.props;
    try {
      setLoading(true);
      const vehicle = await makeRequest(
        `monitor/${id}`,
        { method: 'GET' },
      );
      this.setState({
        modal: {
          show: true,
          vehicle,
        },
      }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  closeModal = () => this.setState({ modal: { show: false, vehicle: null } });

  render() {
    const { map, vehicles, vehiclesFilter, updated, modal } = this.state;

    return (
      <div className="mapa-monitor">
        <div className="map card mirai-shadow">
          <h5 className="card-header">Mapa con ubicación de vehículos en tiempo real</h5>
          <div className="card-body">

            <div className="controls">
              <div className="line">
                <GoogleAutocomplete ref={this.googleAutocomplete} getResult={this.handleGoogleAutocomplete} />
                <WafoFormAutocomplete
                  ref={this.vehicleAutocomplete}
                  name="vehiculo"
                  placeholder="Busqueda por vehículo"
                  items={vehiclesFilter}
                  filterItemsFN={(items, query) => items.filter(item => (
                    item.key_vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    // || item.imei.indexOf(query) !== -1
                  ))}
                  customInputFN={item => `${item.key_vehicle}`}
                  customItemFN={item => (
                    <p style={{ margin: '0' }}>
                      <span>{item.key_vehicle}</span>
                      <br />
                      <span style={{ fontSize: '0.8em', color: '#777' }}>{item.description}</span>
                    </p>
                  )}
                  handleInputChange={this.handleFilterVehicle}
                />
                <StatusFilter
                  ref={this.statusAutocomplete}
                  customClass="col-12 col-md-4"
                  handleInputChange={this.handleFilterChange}
                />
              </div>
              <div className="line">
                <button type="button" className="btn btn-outline-secondary" onClick={this.forceMapUpdate}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={this.clearFilters}>
                  <FontAwesomeIcon icon={faEraser} style={{ marginRight: '.25rem' }} />
                  Limpiar filtros
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={this.resetMap}>
                  <FontAwesomeIcon icon={faMap} style={{ marginRight: '.25rem' }} />
                  Restablecer mapa
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={this.getData}>
                  <FontAwesomeIcon icon={faRedo} style={{ marginRight: '.25rem' }} />
                  Actualizar
                </button>
              </div>
            </div>

            <Map ref={this.setMapRef} key={map.key} center={map.position} zoom={map.zoom} maxZoom={18} animate>
              <TileLayer
                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup>
                {
                  vehicles.map(vehicle => {
                    if (!vehicle.vehicle) { return null; }
                    // marker based on status.
                    const icon = generateDivIcon(vehicle);
                    return (
                      <Marker
                        key={vehicle.vehicle.id_vehicle}
                        position={[vehicle.latitude, vehicle.longitude]}
                        icon={icon}
                        onClick={() => { this.openModal(vehicle.vehicle.id_vehicle); }}
                      />
                    );
                  })
                }
              </MarkerClusterGroup>
            </Map>
          </div>
          <div className="card-footer">
            <p>
              Mostrando un total de <strong>{vehicles.length}</strong> vehículos | Última actualización <strong>{moment(updated).format('hh:mm:ss a')}</strong>
            </p>
          </div>
        </div>
        {modal.show && <ModalMonitor vehicle={modal.vehicle} closeModal={this.closeModal} />}
      </div>
    );
  }
}

export default defaultConnect(Mapa);
