import React from 'react';
import defaultConnect from '../../../../../components/redux/defaultConnect';
import makeRequest from '../../../../../lib/apiService';
import { WafoForm, WafoFormAutocomplete } from '@wafo/forms';
import "./styles.css";

class ModalVehiculo extends React.Component {
    state = {
        list: [],
        vehicle: {
            id_vehicle: 0
        },
        autocomplete: React.createRef()
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const { setLoading, pushError } = this.props;

        try {
            setLoading(true);
            let vehiculos = await makeRequest(
                `vehicles/?limit=99999`,
                { method: 'GET' },
            );
            this.setState({ list: vehiculos.rows }, () => {
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    }

    handleFormSubmit = async (form) => {
        const { setLoading, pushAlerta, pushError, pushSuccess, closeModal, updateTable, id } = this.props;

        if (!form.valid) {
            pushAlerta({
                type: 'warning',
                title: '¡Hay un problema!',
                message: 'Algunos de los campos en la forma tienen problemas.',
                timer: 3000,
            });
            return;
        }

        setLoading(true);
        const body = {
            id_vehicle: form.id_vehicle.value.id_vehicle
        };

        try {
            await makeRequest(
                `geofences/${id}/vehicles`,
                { method: 'PUT' },
                body
            );
            setLoading(false);
            pushSuccess('Se han guardado los cambios.', 3000);
            updateTable();
            closeModal();
        } catch (error) {
            setLoading(false);
            pushError(error.message);
        }
    }

    render() {
        const { list, vehicle } = this.state;
        const { closeModal, ref } = this.props;

        return (
            <div className="modal-vehicle">
                <div className="backdrop">
                    <div className="card animate-in">
                        <div className="card-header">
                            <h5 className="modal-title">
                                Asignar vehículo
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="card-body detalles">
                            <WafoForm
                                key={vehicle.id_vehicle}
                                buttonText="Guardar"
                                onSubmit={this.handleFormSubmit}
                                values={vehicle}
                            >

                                <WafoFormAutocomplete
                                    ref={ref}
                                    name="id_vehicle"
                                    customClass="col-12"
                                    label="Vehículo"
                                    placeholder="Seleccione un vehículo"
                                    items={list}
                                    filterItemsFN={(items, query) => items.filter(item => (
                                        item.key_vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1
                                        || item.plate_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
                                        || item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
                                    ))}
                                    customItemFN={item => `${item.key_vehicle} - ${item.plate_number} - ${item.description}`}
                                    customInputFN={item => `${item.key_vehicle} - ${item.plate_number} - ${item.description}`}
                                />

                            </WafoForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default defaultConnect(ModalVehiculo);