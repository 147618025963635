import React from 'react';
import PropTypes from 'prop-types';

export const Table = ({ columns, data, configTable, pagination, updateTable, paginationEvent, tableClass, zeroRecord }) => {
    console.log(data);

    /**
     * Funciones de tabla
     */

    function configHeaders() {
        return columns.map((column, index) => (
            <th key={index}>{column}</th>
        ));
    }

    function configRows() {
        const { columnDef, columnStyle } = configTable;
        const dataReady = data.map(row => (
            Object.keys(row).map((key) => {
                // Checking if there is a config function for this column.
                if (Object.prototype.hasOwnProperty.call(columnDef, key)) {
                    // return columnDefinition[key](row); If you want the entire object.
                    return {
                        value: columnDef[key](row[key]),
                        style: columnStyle[key],
                    };
                }
                return { value: row[key], style: columnStyle[key] };
            })
        ));

        return dataReady.map((obj, index) => <TableRow key={index} data={obj} />);
    }

    /**
     * Funciones de paginado
     */
    var pages = Math.ceil(pagination.total / pagination.limit);

    const getPages = () => {
        let items = [];
        for (let i = (pagination.page - 3); i <= (pagination.page + 3); i++) {
            if (i > 0 && i <= pages) {
                items.push(i);
            }
        }
        return items.map((elm, index) => {
            if (elm === pagination.page) {
                return <li className="page-item active" key={elm} >
                    <a className="page-link">
                        {elm} <span className="sr-only">(current)</span>
                    </a>
                </li>
            }
            else {
                return <li className="page-item" key={elm} onClick={() => paginationEvent("page", elm)}>
                    <a className="page-link">
                        {elm}
                    </a>
                </li>
            }
        });

    }

    const changeLimit = (e) => {
        paginationEvent("size", parseInt(e.target.value));
    }

    return (
        <>
            <div className={tableClass}>
                {/** Encabezado */}
                <div class="controls">
                    <form class="form-inline">
                        <div class="form-group">
                            <label for="size">Número de registros</label>
                            <select id="size" class="form-control form-control-sm" value={pagination.limit} onChange={changeLimit}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </form>
                    <button type="button" class="btn btn-sm btn-light" onClick={updateTable}>
                        Actualizar tabla
                    </button>
                </div>
                {/** Cuerpo de tabla */}
                <table className="table">
                    <thead>
                        <tr>
                            {configHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? configRows() :
                            <ZeroRecord message={zeroRecord} colSpan={columns.length} />
                        }
                    </tbody>
                </table>
                {/** Paginado */}
                {
                    pagination.total > 0 &&
                    <div class="pagination-wrapper">
                        <p class="description">Mostrando registros del {pagination.page} al {pagination.limit} de {pagination.total} registros.</p>
                        <nav className="pagination pagination-sm nav justify-content-end">

                            <li className="page-item" disabled={pagination.page === 1} onClick={() => paginationEvent("page", pagination.page - 1)}>
                                <a className="page-link prevNext" aria-label="Previous">
                                    Anterior
                                </a>
                            </li>

                            {getPages()}

                            <li className="page-item" disabled={pagination.page === pages} onClick={() => paginationEvent("page", pagination.page + 1)}>
                                <a className="page-link prevNext" >
                                    Siguiente
                                </a>
                            </li>

                        </nav>
                    </div>
                }
            </div>
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    configTable: PropTypes.shape({
        columnDef: PropTypes.any,
        columnStyle: PropTypes.any,
    }),
    pagination: PropTypes.shape({
        total: PropTypes.any,
        page: PropTypes.any,
        limit: PropTypes.any,
    }),
    paginationEvent: PropTypes.any,
    updateTable: PropTypes.any,
    tableClass: PropTypes.string,
};

Table.defaultProps = {
    columns: [],
    data: [],
    configTable: { columnDef: {}, columnStyle: {} },
    pagination: { total: 0, page: 1, limit: 10, pageClick: null, limitChange: null },
    paginationEvent: () => { },
    updateTable: () => { },
    tableClass: 'table',
};

/** ******************************************************* */
/** Componente TableRow */
/** ******************************************************* */

const TableRow = ({ data }) => {
    return <tr>
        {data.map((obj, index) => (
            <td key={index} style={obj.style}>{obj.value}</td>
        ))}
    </tr>
};

TableRow.propTypes = {
    data: PropTypes.array,
};

/**
 * data: [{value: "", style: {...} }, {...}, ...]
 * style is the default React style object
 */
TableRow.defaultProps = {
    data: [],
};

const ZeroRecord = ({ colSpan, message }) => {
    return <tr className="text-center"><td colSpan={colSpan}>{message}</td></tr>
}

ZeroRecord.defaultProps = {
    colSpan: 1,
    message: 'No existen registros.'
}

export default Table;