import React from 'react';
import { connect } from 'react-redux';
import './styles.css';

const LoadingBar = ({
  show = false, showBar = false, color = "#333", backgroundColor = "#ddd",
  wrapperBackgroundColor = "transparent", height = ".5rem"
}) => {
  if (!show) { return null; }
  return (
    <div className="loading-bar wrapper" style={{ backgroundColor: wrapperBackgroundColor, height }}>
      {showBar && (
        <div className="sl-loadingBar" style={{ backgroundColor, height }}>
          <span className="innerBar" style={{ backgroundColor: color, height }} />
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    showBar: state.loadingBar,
  }),
  () => ({})
)(LoadingBar);
