import jwt from 'jsonwebtoken';
import { baseUrl } from './constants';

export function storeToken(token) {
  sessionStorage.setItem('token', token);
}

export function getToken() {
  return sessionStorage.getItem('token');
}

export async function refreshToken() {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}auth/refresh`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      method: 'GET',
    });
    if (!response.ok) {
      logout();
      return null;
    }
    const newToken = await response.json();
    storeToken(newToken.token);
    return newToken.token;
  } catch (error) {
    console.error(error);
  }
}

export function isTokenExpired(token) {
  try {
    const decoded = jwt.decode(token);
    if (decoded.exp < (Date.now() / 1000)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function logout() {
  sessionStorage.removeItem('token');
}