import React from 'react';
import moment from 'moment-timezone';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

import InfoVehiculo from './detallesVehiculo/infoVehiculo';
import LogsGeocerca from './detallesVehiculo/logsGeocerca';
import GeocercasAsignadas from './detallesVehiculo/geocercasAsignadas/geocercasAsignadas';

moment.locale('es');

class DetallesVehiculo extends React.Component {
  state = {
    vehicle: {},
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const vehicle = await makeRequest(
        `vehicles/${id}`,
        { method: 'GET' },
      );
      this.setState({ vehicle }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { vehicle } = this.state;
    const { match: { params: { id } } } = this.props;

    let disabled = null;
    switch (vehicle.disabled) {
      case true:
        disabled = <span className="badge badge-warning">Deshabilitado</span>;
        break;
      case false:
        disabled = <span className="badge badge-success">Activo</span>;
        break;
      default:
        disabled = <span className="badge badge-dark">N/A</span>;
        break;
    }

    let power = null;
    switch (vehicle.power) {
      case true:
        power = <span className="badge badge-success">Encendido</span>;
        break;
      case false:
        power = <span className="badge badge-danger">Apagado</span>;
        break;
      default:
        power = <span className="badge badge-dark">N/A</span>;
        break;
    }

    let linked = null;
    switch (vehicle.linked) {
      case true:
        linked = <span className="badge badge-success">Vinculado</span>;
        break;
      case false:
        linked = <span className="badge badge-warning">Sin vincular</span>;
        break;
      default:
        linked = <span className="badge badge-dark">N/A</span>;
        break;
    }

    return (
      <div className="detalles-concesionario detalles">
        <div className="row">

          <div className="col-12 header">
            <h3>{vehicle.key_vehicle} - {vehicle.description}</h3>
            {disabled}
            {linked}
            {power}
          </div>

          <div className='col-12 col-md-6'>
            <InfoVehiculo
              vehicle={vehicle}
            />
          </div>

          <div className='col-12 col-md-6'>

            <GeocercasAsignadas
              id={id} />

          </div>

        </div>
        <div className='row pt-4'>
          {/** Logs de geocerca */}
          <div className='col-12'>

            <LogsGeocerca
              id={id} />

          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(DetallesVehiculo);
