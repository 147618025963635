import React from 'react';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import { Redirect } from 'react-router-dom';
import meConnect from '../../../components/redux/meConnect';
import makeRequest from '../../../lib/apiService';

class FormaChofer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driver: {
        id_driver: 0,
      },
      redirect: false,
      concesionarios: [],
    };

    this.autocompleteConcesionario = React.createRef();
  }

  async componentDidMount() {
    const { match: { params: { id } }, me } = this.props;
    if (Object.keys(me).length !== 0) {
      await this.checkMe();
      if (id) {
        this.getChofer();
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { match: { params: { id } }, me } = this.props;
    if (prevProps.me !== me) {
      await this.checkMe();
      if (id) {
        this.getChofer();
      }
    }
  }

  checkMe = async () => {
    const { me } = this.props;
    if (me.type === 1) {
      await this.getConcesionarios();
    } else {
      this.setState(prevState => ({
        driver: {
          ...prevState.driver,
          id_driver: Math.random(),
          id_user: me,
        },
        concesionarios: [me],
      }));
    }
  }

  getConcesionarios = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      let concesionarios = await makeRequest(
        `users/?limit=99999`,
        { method: 'GET' },
      );
      this.setState({ concesionarios: concesionarios.rows }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  getChofer = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const driver = await makeRequest(
        `drivers/${id}`,
        { method: 'GET' },
      );

      const id_user = this.state.concesionarios.find(x => x.id_user === driver.id_user);
      driver.id_user = id_user;

      this.setState({ driver });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFormSubmit = async (form) => {
    const { setLoading, pushAlerta, pushError, pushSuccess, match: { params: { id } } } = this.props;

    if (!form.valid) {
      pushAlerta({
        type: 'warning',
        title: '¡Hay un problema!',
        message: 'Algunos de los campos en la forma tienen problemas.',
        timer: 3000,
      });
      return;
    }

    setLoading(true);
    const body = {
      name: form.name.value,
      last_name: form.last_name.value,
      email: form.email.value,
      phone_number: form.phone_number.value,
      id_user: form.id_user.value.id_user,
    };

    try {
      await makeRequest(
        `drivers${id ? `/${id}` : ''}`,
        { method: id ? 'PUT' : 'POST' },
        body
      );
      setLoading(false);
      pushSuccess('Se han guardado los cambios.', 3000);
      this.setState({ redirect: true });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { driver, redirect, concesionarios } = this.state;

    if (redirect) {
      return <Redirect to="/panel/choferes" />;
    }

    return (
      <div className="forma-chofer">
        <WafoForm
          key={driver.id_driver}
          buttonText="Guardar cambios"
          onSubmit={this.handleFormSubmit}
          values={driver}
        >

          <WafoFormInput
            type="text"
            name="name"
            placeholder="Nombre"
            label="Nombre(s)"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="last_name"
            placeholder="Apellidos"
            label="Apellidos"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="email"
            name="email"
            placeholder="Correo electrónico"
            label="Correo electrónico"
            customClass="col-12 col-md-4"
            validations={{ email: true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="phone_number"
            placeholder="Número de télefono"
            label="Número de télefono"
            customClass="col-12 col-md-4"
            validations={{ phone: true }}
          />

          <WafoFormAutocomplete
            ref={this.autocompleteConcesionario}
            name="id_user"
            customClass="col-12 col-md-4"
            label="Concesionario"
            placeholder="Seleccione un concesionario"
            items={concesionarios}
            filterItemsFN={(items, query) => items.filter(item => (
              item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || `${item.name} ${item.last_name}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || (item.description && item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ))}
            customItemFN={item => (
              <p style={{ margin: '0' }}>
                <span>{item.name} {item.last_name}</span>
                <br />
                <span style={{ fontSize: '0.8em', color: '#777' }}>#{item.id_user} - {item.description}</span>
              </p>
            )}
            customInputFN={item => `${item.name} ${item.last_name}`}
          />

        </WafoForm>
      </div>
    );
  }
}

export default meConnect(FormaChofer);
